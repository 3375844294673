import { useEffect } from "react"

export function GoogleBannerH(props) {
  useEffect(() => {
    if (window) {
      // // @ts-ignore
      // ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }, [])

  return (
    <ins
      className="adsbygoogle block"
      data-ad-client="ca-pub-5945133947018927"
      data-ad-slot="3273659007"
      data-ad-format="auto"
      data-full-width-responsive="true"
      {...props}
    />
  )
}

export const GoogleBannerV = (props) => {
  useEffect(() => {
    if (window) {
      console.log("Init googlebanner V")
      // // @ts-ignore
      // try {
      //   ;(window.adsbygoogle = window.adsbygoogle || []).push({})
      // } catch (err) {
      //   console.error("Error initializing GoogleBannerV", err)
      // }
    }
  }, [])
  return (
    <ins
      className="adsbygoogle block w-full h-full"
      data-ad-client="ca-pub-5945133947018927"
      data-ad-slot="1404391172"
      data-ad-format="auto"
      data-full-width-responsive="true"
      {...props}
    />
  )
}

export const GoogleBannerV2 = (props) => {
  useEffect(() => {
    if (window) {
      // // @ts-ignore
      // ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }, [])
  return (
    <ins
      className="adsbygoogle block"
      data-ad-client="ca-pub-5945133947018927"
      data-ad-slot="4757312825"
      data-ad-format="auto"
      data-full-width-responsive="true"
      {...props}
    />
  )
}
