const cloudinaryPrefix = "/image/upload/"

export const Photo = ({
  media,
  alt = "",
  radius = true,
  imgWidth,
  className,
}: {
  media: any
  alt?: string
  radius?: boolean
  imgWidth?: number
  className?: any
}) => {
  const url =
    typeof media === "string"
      ? media
      : media?.url ?? "/assets/venues/default.jpg"

  const cloudinaryPrefixIndex = url.indexOf(cloudinaryPrefix)
  let trasformedPath = url
  // Transform with cloudinary
  if (cloudinaryPrefixIndex >= 0 && typeof media !== "string" && imgWidth) {
    const relativePath = url.substring(
      cloudinaryPrefixIndex + cloudinaryPrefix.length
    )
    trasformedPath = imgWidth ? `/w_${imgWidth}/` + relativePath : relativePath
    trasformedPath = url.substring(0, cloudinaryPrefixIndex) + trasformedPath
    return (
      <div
        className={`${className} relative h-full w-full overflow-hidden radius rounded-lg`}
      >
        {/* Don't do automatic cloudinary url generation as we have images from two sources */}
        {/* <Image src={trasformedPath} alt={alt} layout="fill" objectFit="cover" /> */}
        <img
          className="object-cover w-full h-full"
          src={trasformedPath}
          alt={alt}
        />
      </div>
    )
  } else {
    return (
      <div
        className={`${className} relative h-full w-full overflow-hidden ${
          radius ? `rounded-lg` : ""
        }`}
      >
        <img
          className="object-cover w-full h-full"
          src={trasformedPath}
          alt={alt}
        />
      </div>
    )
  }
}
