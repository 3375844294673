import { HomeCategoriesSlider } from "./HomeCategoriesSlider"
import { HomeSearchBar } from "./search/HomeSearchBar"

export const HomeHero22 = (props) => {
  return (
    <div
      className="
        md:pt-[var(--moje-header-height)] relative
        pb-16
        mb-8
        "
    >
      <div
        className="
        h-[338px]
        md:h-[600px]
        lg:h-[600px] 
        w-full
        flex
        flex-col
        items-center
        justify-center
        object-cover
        relative
        "
      >
        <div
          className="absolute w-full h-full z-0 bg-no-repeat bg-cover bg-center object-cover"
          style={{
            background: 'url("/assets/home/home_hero_22.png") no-repeat',
            backgroundSize: "cover",
          }}
        ></div>

        <div
          className="text-white font-semibold 
          md:text-4xl 
          hidden xl:block
          mb-4 z-10"
        >
          Zorganizuj przyjęcie
        </div>
        <div
          className="text-white font-semibold 
          text-normal lg:text-2xl 
          mx-4 lg:mx-12
          text-center
          mb-6 z-10
          mt-16 lg:mt-0
          "
        >
          Odkryj najlepsze urodzinowe lokalizacje i usługi w Twojej okolicy!
        </div>
        {/* <div className="hidden xl:block"> */}
        <HomeSearchBar />
        {/* </div> */}

        {/* <div className="h-16 xl:h-52"></div> */}
      </div>
      <div
        className="w-full 
        xl:translate-y-28 translate-y-14
        absolute
        bottom-0
        left-0
        right-0
        "
      >
        <HomeCategoriesSlider />
      </div>
    </div>
  )
}
