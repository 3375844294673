import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SectionTitle from "../../components/home/SectionTitle"
export const HomeSectionLocations = ({ stats }) => {
  return (
    <div
      className="container 
      max-w-screen-xl 
      m-auto my-16 px-4
      xl:mt-32
    "
    >
      <SectionTitle>
        Zorganizuj przyjęcie z MojeUrodziny.pl według miasta
      </SectionTitle>

      <div
        className="grid 
          grid-cols-1 xl:grid-cols-4 gap-2"
      >
        {stats?.locations &&
          stats.locations
            .slice(0, Math.min(20, stats.locations.length))
            .map((loc) => (
              <div className="col-span-1 flex flex-rows align-center mb-4">
                <div
                  className="text-[#BBCE00] mr-4 
                  text-sm lg:text-lg"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className="  text-sm lg:text-lg">{loc.name}</div>
              </div>
            ))}
      </div>
    </div>
  )
}
