import React from "react"

export default function SectionTitle(
  props: React.ComponentPropsWithoutRef<"h4">
) {
  return (
    <h3
      {...props}
      className={`text-center 
      text-xl md:text-3xl 
      my-1 lg:my-2
      font-semibold font-serif ${props.className}`}
    />
  )
}
