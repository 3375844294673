import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const ScrollButton = ({ onClick, delta }) => (
  <div
    onClick={(ev: any) => onClick(delta)}
    className="w-[109px] h-[109px] bg-white rounded-full flex items-center justify-center"
    style={{
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    }}
  >
    <FontAwesomeIcon
      icon={delta < 0 ? faChevronLeft : faChevronRight}
      size="3x"
    />
  </div>
)
