import Link from "next/link"
import Button from "@material-ui/core/Button"
import { CenteredPageWrapper } from "../../components/page/centered-page-wrapper"
import SectionTitle from "../../components/home/SectionTitle"
import HorizontalScroll, {
  HorizontalScrollItem,
} from "../../components/HorizontalScroll"

import { HomeBlogTile } from "./HomeBlogTile"
import { GoogleBannerV } from "../../components/socials/GoogleBanner"

export const HomeSectionInspiratons = ({ articles }) => {
  const button = (
    <Link href="/inspiracje" passHref>
      <Button component="a" variant="outlined" color="primary">
        Wybierz
      </Button>
    </Link>
  )
  return (
    <section className="md:py-8">
      <div className="container max-w-screen-xl px-4 mx-auto">
        <SectionTitle>Inspiracje</SectionTitle>
        <div
          className="font-light 
          text-gray-600
          text-center"
        >
          <p>
            Nie wiesz jak zaplanować przyjęcie? My Ci w tym pomożemy. Poznaj
            nasze porady i wskazówki.
          </p>
          <p>
            Nie wiesz jak zaplanować przyjęcie? My Ci w tym pomożemy. Poznaj
            nasze porady i wskazówki.
          </p>
        </div>

        {/* <div className="flex flex-col md:grid grid-cols-3 mt-8">
        <div className="flex flex-col md:grid grid-cols-3 mt-8"> */}
        {/* <div className="col-span-1 order-last md:order-first p-3">
            <GoogleBannerV />
          </div> */}
      </div>
      <div className="my-4 md:my-8 container max-w-screen-xl m-auto px-4 md:px-0">
        <HorizontalScroll className="col-span-2 flex flex-row ">
          {articles &&
            Array.isArray(articles) &&
            articles.map((art: any, index) => (
              // <HorizontalScrollItem key={index}>
              <div key={index} className="inline-block pr-4 pb-2">
                <HomeBlogTile
                  href={`/inspiracje/${art.slug ?? art.id}`}
                  img={
                    art.media && art.media.length > 0
                      ? art.media[0].url
                      : undefined
                  }
                  title={art.title}
                />
              </div>
              // </HorizontalScrollItem>
            ))}
          {/* //           <HorizontalScrollItem> 
             <HomeBlogTile
//               title="Tata w akcji. Poradnik o organizacji urodzin
// dla ojców"
//               href="/inspiracje/tata-w-akcji-poradnik-o-organizacji-urodzin-dla-ojcow"
//               img="https://res.cloudinary.com/mojeurodziny/image/upload/c_scale,q_100,w_800/v1616586252/blog/AdobeStock_258535593_yietip.webp"
//             />
//           </HorizontalScrollItem>
//           <HorizontalScrollItem>
//             <HomeBlogTile
//               title="Urodziny dawniej i dziś – czego oczekują
// współczesne dzieci?"
//               href="/inspiracje/urodziny-dawniej-i-dzis-czego-oczekuja-wspolczesne-dzieci"
//               img="https://res.cloudinary.com/mojeurodziny/image/upload/c_scale,q_100,w_800/v1616586252/blog/AdobeStock_320581544_iluxzw.webp"
//             />
//           </HorizontalScrollItem> */}
        </HorizontalScroll>
      </div>
      {/* <div className="md:hidden my-2 mx-4 text-center">{button}</div>
        </div> */}
      {/* <div className="text-center my-2 hidden md:block relative">
          {button}
          <svg
            className="hidden md:block absolute -left-16 -bottom-8"
            width="113"
            height="158"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.495 67.957c-.425-3.826 3.45-6.676 6.976-5.131l52.554 23.032c3.526 1.545 4.056 6.327.956 8.607l-46.224 33.998c-3.1 2.28-7.507.349-7.932-3.477l-6.33-57.03z"
              fill="#BBCE00"
              stroke="#BBCE00"
              strokeWidth="2"
            />
            <rect
              x="31.175"
              y="36.631"
              width="34.868"
              height="34.868"
              rx="6"
              transform="rotate(-37.975 31.175 36.63)"
              fill="#FDC600"
            />
            <circle cx="31.5" cy="7.5" r="7.5" fill="#602482" />
          </svg>
        </div>
      </div> */}
    </section>
  )
}
