export const TrianglesDecoration = () => (
  <svg
    className=""
    // width="96"
    // height="86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144 180"
  >
    <path
      d="M82.171 98.724c-3.113-3.412-1.365-8.915 3.146-9.905l75.635-16.6c4.512-.99 8.403 3.274 7.005 7.676l-23.441 73.803c-1.398 4.402-7.037 5.639-10.151 2.227L82.171 98.723z"
      fill="#D50F2F"
    />
    <rect
      x="79.272"
      width="47.27"
      height="47.39"
      rx="10"
      transform="rotate(38.148 79.272 0)"
      fill="#BBCE00"
    />
    <path
      d="M124.059 81.038c-.527 6.92-6.342 12.053-12.915 11.552-6.573-.5-11.543-6.456-11.016-13.376.528-6.919 6.343-12.052 12.916-11.552 6.573.501 11.542 6.457 11.015 13.376z"
      stroke="#602482"
      strokeWidth="2"
    />
    <path
      d="M10.85 80.528c-1.114-1.09-.608-2.978.9-3.366l15.799-4.06a2 2 0 001.294-1.049l7.252-14.61c.693-1.396 2.645-1.498 3.48-.183l8.744 13.77a2 2 0 001.396.907l16.137 2.382c1.541.228 2.242 2.053 1.25 3.253L56.706 90.144a2 2 0 00-.43 1.607l2.72 16.084c.26 1.536-1.26 2.766-2.708 2.193l-15.168-6.001a2 2 0 00-1.662.088l-14.456 7.557c-1.38.722-3.02-.343-2.922-1.898l1.02-16.28a2 2 0 00-.597-1.553L10.849 80.528z"
      fill="#FDC600"
    />
  </svg>
)
