import { useRef } from "react"
import { ScrollButton } from "../../components/ScrollButton"

const _CategoryTile = ({ label, img }) => (
  <div
    className="
    w-[110px] lg:w-[220px] 
    h-[110px] lg:h-[220px] 
    bg-white 
    mr-2 xl:mr-4 
    inline-block 
    rounded-[16px]
    lg:rounded-[16px] 
    p-2 xl:p-4 "
    style={{
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    }}
  >
    <div className="flex flex-col justify-center items-center h-full">
      <div
        className="
        xl:w-[198px] xl:h-[198px] 
        flex-1
        min-h-0
        mb-1 xl:mb-4 
        flex flex-row 
        justify-center 
        xl:pt-2"
      >
        <img src={img} className="w-full h-full rounded-xl"></img>
      </div>
      <div
        className="font-medium 
        text-[10px] lg:text-lg
        text-center 
        px-2 lg:px-3
        whitespace-normal 
        h-6 lg:h-8
        overflow-hidden
        "
      >
        {label}
      </div>
    </div>
  </div>
)

const categories = [
  {
    label: "Sale Zabaw",
    href: "/kategoria/sale-zabaw",
    img: "/assets/categories/128/128_Sale_Zabaw.png",
  },
  {
    label: "Baseny",
    href: "/kategoria/baseny",
    img: "/assets/categories/128/128_Baseny.png",
  },
  {
    label: "Trampoliny",
    href: "/kategoria/trampoliny",
    img: "/assets/categories/128/128_Trampoliny.png",
  },
  {
    label: "Escape Room",
    href: "/kategoria/escape-room",
    img: "/assets/categories/128/128_Escape_Roomy.png",
  },
  {
    label: "Ścianki Wspinaczkowe",
    href: "/kategoria/scianki",
    img: "/assets/categories/128/128_Scianki_Wspinaczkowe.png",
  },
  {
    label: "Parki linowe",
    href: "/kategoria/scianki",
    img: "/assets/categories/128/128_Sale_Zabaw.png",
  },

  {
    label: "Sale Zabaw",
    href: "/kategoria/sale-zabaw",
    img: "/assets/categories/128/128_Sale_Zabaw.png",
  },
  {
    label: "Baseny",
    href: "/kategoria/baseny",
    img: "/assets/categories/128/128_Baseny.png",
  },
  {
    label: "Trampoliny",
    href: "/kategoria/trampoliny",
    img: "/assets/categories/128/128_Trampoliny.png",
  },
  {
    label: "Escape Room",
    href: "/kategoria/escape-room",
    img: "/assets/categories/128/128_Escape_Roomy.png",
  },
  {
    label: "Ścianki Wspinaczkowe",
    href: "/kategoria/scianki",
    img: "/assets/categories/128/128_Scianki_Wspinaczkowe.png",
  },
  {
    label: "Parki linowe",
    href: "/kategoria/scianki",
    img: "/assets/categories/128/128_Sale_Zabaw.png",
  },
]

export const HomeCategoriesSlider = () => {
  const scrollRef = useRef<HTMLDivElement>()

  const slide = (direction) => {
    if (scrollRef.current) {
      scrollRef.current!.scrollLeft =
        scrollRef.current!.scrollLeft + direction * 160
    }
  }
  return (
    <div className="pb-8">
      <div className=" m-auto flex flex-row items-center mx-2 xl:mx-8">
        <div
          className="translate-x-8 
          hidden xl:block"
        >
          <ScrollButton delta={-1} onClick={slide} />
        </div>
        <div
          ref={scrollRef}
          id="categoriesScrollContainer"
          className="overflow-x-hidden 
            whitespace-nowrap pb-8 
            flex-1 
            scroll-smooth"
        >
          {categories.map((cat, index) => (
            <_CategoryTile label={cat.label} img={cat.img} key={index} />
          ))}
        </div>
        <div
          className="-translate-x-8
          hidden xl:block"
        >
          <ScrollButton delta={1} onClick={slide} />
        </div>
      </div>
    </div>
  )
}
