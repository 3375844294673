export const HomeHostCTA22 = () => {
  return (
    <div className=" m-auto px-2">
      <div
        // bg-[#D67085]
        className="
        m-auto
        w-[288px] sm:w-[656px] xl:w-[1312px]
        h-[267px] sm:h-[368px] xl:h-[416px]
        flex flex-row rounded-xl 
        overflow-hidden 
        bg-no-repeat
        bg-[url(/assets/home/business_hero_xs.png)]
        sm:bg-[url(/assets/home/business_hero_720.png)]
        xl:bg-[url(/assets/home/business_hero_lg.png)]        
      "
      >
        {/* <div
          className="absolute 
          left-[-180px]
          xl:left-[-120px] 
          top-0 bottom-0 
          rounded-xl h-[392px]"
        >
          <img
            src="g"
            className="max-w-initial h-full"
            style={{
              maxWidth: "initial",
            }}
          ></img>
        </div> */}
        {/* // <div
        //   className="flex-1
        // "
        // >
        //   <img
        //     src="/assets/home/business_hero_bg.png"
        //     className="h-full w-full object-cover rounded-xl"
        //   />
        // </div> */}
        <div className="w-1/2 z-10"></div>
        <div className="w-1/2 z-10">
          <div
            className="
              px-2 sm:px-6
              py-4 sm:py-8 
              flex flex-col
              h-full
              justify-center
              items-center xl:items-end
              xl:pr-16
              "
          >
            {/* <div
              className="
                text-normal md:text-2xl xl:text-4xl 
                font-semibold 
                my-2 xl:my-4
                pr-2 md:pr-0"
            >
              Twoje miejsce lub usługi są idealne na urodziny?
            </div>
            <div
              className="my-4 
          text-xs xl:text-2xl 
          font-normal "
            >
              Dodaj swoją ofertę do największej bazy urodzinowej w Polsce.
              Zyskaj nowe rezerwacje oraz nowe możliwości rozwoju.
            </div> */}
            <div className="flex-1" />
            {/* <div
              className="my-4 
              text-right"
            > */}
            <button
              className="rounded-full bg-[#E30074] text-white 
               w-full 
               w-32 md:w-32
               px-2 sm:px-8 md:px-8 
               py-2 sm:py-3
               sm:text-md
               cursor-pointer"
            >
              Dla firm
            </button>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
