import { Link } from "../../components/Link"

export const HomeBlogTile = (
  {
    title,
    href,
    img,
  }: {
    title: string
    href?: string
    img?: string
  } = {
    title: "Title",
  }
) => {
  const content = (
    <article
      className="relative 
      w-[245px]
      h-[242px]
      rounded-xl 
      cursor-pointer
      flex flex-col
      "
      style={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div
        className="inset-0 bg-cover flex-1 rounded-2xl"
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 14.02%, rgba(0, 0, 0, 0) 50%), url('${img}')`,
          backgroundSize: "cover",
        }}
      />
      <div
        className="bottom-0 text-black 
        p-2
        h-16 md:h-16
        w-full
        text-overflow-ellipsis
        overflow-hidden
        "
      >
        {title && (
          <h3
            className="
              text-sm md:text-base
              font-semibold
              text-overflow-ellipsis
              overflow-hidden
              max-h-full
            "
          >
            {title}
          </h3>
        )}
      </div>
    </article>
  )

  return href ? <Link href={href}>{content}</Link> : content
}
