import Link from "next/link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

const FooterColumn = ({
  title,
  children,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & { title: string }) => {
  return (
    <div id="FooterColumn" {...props} className="flex-auto w-full mb-2">
      <div className="font-medium mb-6 text-lg">{title}</div>
      <div>{children}</div>
    </div>
  )
}
const FooterItem = ({
  href,
  children,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & { href?: string }) => {
  return (
    <div className="my-2 md:my-0 md:mb-1 text-sm" {...props}>
      {href ? <Link href={href}>{children}</Link> : children}
    </div>
  )
}

export const MojeFooter = (props) => {
  return (
    <div className="p-8 bg-[#F5FAFD]">
      <div className="xl:max-w-screen-lg m-auto">
        <div className="md:flex gap-2">
          <div className="md:w-1/2 flex gap-2">
            <FooterColumn title="Dla firm" className="row-span-2">
              <FooterItem>
                <a href="https://admin.mojeurodziny.pl" target="_blank">
                  Dołącz do nas
                </a>
              </FooterItem>
              <FooterItem>
                <a href="https://admin.mojeurodziny.pl/login" target="_blank">
                  Logowanie
                </a>
              </FooterItem>
            </FooterColumn>
            <FooterColumn title="Oferta" className="row-span-2">
              <FooterItem href="/kategoria/sale-zabaw">Sale zabaw</FooterItem>
              <FooterItem href="/kategoria/sportowe">
                Obiekty sportowe
              </FooterItem>
              <FooterItem href="/kategoria/restauracje">Restauracje</FooterItem>
              <FooterItem href="/kategoria/kawiarnie">Kawiarnie</FooterItem>
              <FooterItem href="/kategoria/animacje">Animacje</FooterItem>
              <FooterItem href="/kategoria/fotograf">Fotograf</FooterItem>
              <FooterItem href="/kategoria/cukiernie">Cukiernie</FooterItem>
              <FooterItem href="/kategoria/catering">Catering</FooterItem>
            </FooterColumn>
          </div>
          <div className="md:w-1/2">
            <div className="flex gap-2">
              <FooterColumn title="Inspiracje">
                <FooterItem href="/inspiracje">Dekoracja</FooterItem>
                <FooterItem href="/inspiracje">Konkursy</FooterItem>
                <FooterItem href="/inspiracje">Planowanie przyjęcia</FooterItem>
                <FooterItem href="/inspiracje">Testy zabawek</FooterItem>
              </FooterColumn>

              <FooterColumn title="O nas">
                <FooterItem>
                  <Link href="/o-portalu">
                    <a href="/o-portalu"></a>
                  </Link>
                </FooterItem>
                <FooterItem href="/kontakt">Kontakt</FooterItem>
                <FooterItem href="/kariera">Kariera</FooterItem>
                <FooterItem href="/polityka-prywatnosci">
                  Polityka Prywatności
                </FooterItem>
                <FooterItem href="/projekty">Projekty EU</FooterItem>
                <FooterItem href="/regulamin">Regulamin</FooterItem>
                <FooterItem href="/faq">FAQ</FooterItem>
                <div className="my-2 md:my-2 md:mb-1 flex flex-row">
                  <div className="cursor-pointer mr-2">
                    <a
                      href="https://www.instagram.com/mojeurodziny.pl/"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                  </div>
                  <div className="mr-2 cursor-pointer">
                    <a
                      href="https://www.facebook.com/MojeUrodzinypl"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </a>
                  </div>
                </div>
              </FooterColumn>
            </div>
          </div>
        </div>
        <div className="mt-2 md:mt-8 text-center">
          <img src="/assets/ue/ue-logo-vert.png" className=" md:w-1/2 m-auto" />
        </div>
      </div>

      {/* <div className="grid grid-cols-2 gap-2 md:flex max-w-screen-xl m-auto mt-2 text-sm">
        <FooterColumn title="Sale Zabaw">
          <FooterItem href="/sportowe/sale-zabaw/warszawa">
            Sale zabaw - Warszawa
          </FooterItem>
          <FooterItem href="/sportowe/sale-zabaw/lodz">
            Sale zabaw - Łódź
          </FooterItem>
        </FooterColumn>
        <FooterColumn title="Restauracje">
          <FooterItem href="/gastronomia/restauracje/warszawa">
            Restauracje - Warszawa
          </FooterItem>
          <FooterItem href="/gastronomia/restauracje/lodz">
            Restauracje - Łódź
          </FooterItem>
        </FooterColumn>
        <FooterColumn title="Animacje">
          <FooterItem href="/uslugi/animacje/warszawa">
            Animacje - Warszawa
          </FooterItem>
          <FooterItem href="/uslugi/animacje/lodz">Animacje - Łódź</FooterItem>
        </FooterColumn>
        <FooterColumn title="Fotograf">
          <FooterItem href="/uslugi/fotograf/warszawa">
            Fotograf - Warszawa
          </FooterItem>
          <FooterItem href="/uslugi/fotograf/lodz">Fotograf - Łódź</FooterItem>
        </FooterColumn>
        <FooterColumn title="Cukiernie">
          <FooterItem href="/uslugi/cukiernie/warszawa">
            Cukiernie - Warszawa
          </FooterItem>
          <FooterItem href="/uslugi/cukiernie/lodz">
            Cukiernie - Łódź
          </FooterItem>
        </FooterColumn>
        <FooterColumn title="Catering">
          <FooterItem href="/uslugi/catering/warszawa">
            Catering - Warszawa
          </FooterItem>
          <FooterItem href="/uslugi/catering/lodz">Catering - Łódź</FooterItem>
        </FooterColumn>
      </div> */}
    </div>
  )
}
