import { TrianglesDecoration } from "../../components/TrianglesDecoration"

const Title1 = ({ children }) => (
  <div
    className="font-medium 
              text-normal md:text-xl
              pr-2 xl:pr-8 
              mb-2 xl:mb-8 
              max-w-2xl 
              leading-relaxed"
  >
    {children}
  </div>
)
const Subtitle = ({ children }) => (
  <div
    className="font-light 
text-xs md:text-normal
pr-4 
mb-4 xl:mb-8"
  >
    {children}
  </div>
)
export const HomeSection1 = () => {
  return (
    <div>
      <div
        className="bg-[#007BC308] 
        rounded-2xl 
        container
        max-w-screen-xl 
        p-4 xl:p-8 
        py-8 xl:py-16 
        mx-4 xl:mx-16 
        relative
        md:mt-16
        md:max-w-screen-xl 
        xl:mx-auto
        "
      >
        <div
          className="absolute 
          top-[-25px] md:top-[-5px] 
          left-[-15px] md:left-[5px] 
          -rotate-12
          w-[48px]
          h-[48px]"
        >
          <TrianglesDecoration />
        </div>
        <div
          className="absolute right-[-5px] bottom-[5px]
         w-[48px]
         h-[48px]
         "
        >
          <TrianglesDecoration />
        </div>
        <div
          className="flex flex-col-reverse
          xl:flex-row 
          justify-center"
        >
          <div
            className="
              h-[220px] md:h-full
              w-full md:w-1/3
              md:pr-16
              my-4 md:my-0
            "
          >
            <div className="bg-gray-200 rounded-3xl h-full m-auto"></div>
          </div>
          <div className="flex-1 max-w-4xl">
            <Title1>Nie masz pomysłu i czasu na przygotowanie urodzin?</Title1>
            <Subtitle>
              <p className="mb-4">
                MojeUrodziny.pl to portal skupiający oferty związane z
                organizają urodzin. Sale zabaw, restauracje, animatorzy, torty,
                codzienne dawki inspiracji i wiele więcej.{" "}
              </p>
              <p className="mb-4">
                Mamy dla Ciebie kilkaset ofert, a aby ułatwić Ci podjęcie
                najlepszej decyzji wszystkie sprawdziliśmy i dopytaliśmy o
                szczegóły.
              </p>
              <p className="mb-4">
                Mamy dla Ciebie kilkaset ofert, a aby ułatwić Ci podjęcie
                najlepszej decyzji wszystkie sprawdziliśmy i dopytaliśmy o
                szczegóły.{" "}
              </p>
            </Subtitle>
          </div>
        </div>

        <div
          className="flex flex-col xl:flex-row 
          justify-center 
          mt-4 xl:mt-16"
        >
          <div className="flex-1 max-w-4xl">
            <Title1>Nie masz pomysłu i czasu na przygotowanie urodzin?</Title1>
            <Subtitle>
              <p className="mb-4">
                MojeUrodziny.pl to portal skupiający oferty związane z
                organizają urodzin. Sale zabaw, restauracje, animatorzy, torty,
                codzienne dawki inspiracji i wiele więcej.{" "}
              </p>
              <p className="mb-4">
                Mamy dla Ciebie kilkaset ofert, a aby ułatwić Ci podjęcie
                najlepszej decyzji wszystkie sprawdziliśmy i dopytaliśmy o
                szczegóły.
              </p>
              <p className="mb-4">
                Mamy dla Ciebie kilkaset ofert, a aby ułatwić Ci podjęcie
                najlepszej decyzji wszystkie sprawdziliśmy i dopytaliśmy o
                szczegóły.{" "}
              </p>
            </Subtitle>
          </div>
          <div
            className="
              h-[220px] md:h-full
              w-full md:w-1/3
              md:pr-16
              my-4 md:my-0"
          >
            <div className="bg-gray-200 rounded-2xl h-full"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
