import { useState, useEffect, useRef } from "react"
import { debounce } from "@material-ui/core"
import SectionTitle from "../../components/home/SectionTitle"
import HorizontalScroll, {
  HorizontalScrollItem,
} from "../../components/HorizontalScroll"
import { CenteredPageWrapper } from "../../components/page/centered-page-wrapper"

import { HomeVenueTile } from "./HomeVenueTile"
import { TrianglesDecoration } from "../../components/TrianglesDecoration"
import { GoogleBannerV } from "../../components/socials/GoogleBanner"

function FacebookPageEmbed() {
  const [facebookPageWidth, setFacebookPageWidth] = useState<number>()
  const [facebookPageHeight, setFacebookPageHeight] = useState<number>()
  const facebookPageContainer = useRef<HTMLDivElement>()

  function resizeHandler() {
    setFacebookPageWidth(facebookPageContainer.current?.clientWidth)
    setFacebookPageHeight(facebookPageContainer.current?.clientHeight)
    // @ts-ignore
    if (window.FB) window.FB.XFBML.parse()
  }

  useEffect(() => {
    resizeHandler()
    const resizeObserver = new ResizeObserver(debounce(resizeHandler, 1000))
    resizeObserver.observe(facebookPageContainer.current)
    return () => resizeObserver.disconnect()
  }, [])
  return (
    <div
      className="h-full"
      ref={facebookPageContainer}
      dangerouslySetInnerHTML={{
        __html:
          facebookPageWidth && facebookPageHeight
            ? `
          <div
            class="fb-page"
            data-href="https://www.facebook.com/MojeUrodzinypl"
            data-tabs="timeline"
            data-width="${facebookPageWidth}"
            data-height="${facebookPageHeight}"
            data-small-header="false"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="true"
          >
            <blockquote
              cite="https://www.facebook.com/MojeUrodzinypl"
              class="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/MojeUrodzinypl">MojeUrodziny</a>
            </blockquote>
          </div>
          `
            : null,
      }}
    />
  )
}

export const HomeSectionRecentlyAdded = ({ venues }) => {
  return (
    <CenteredPageWrapper component="section" className="py-8">
      <div className="mb-4">
        <SectionTitle className="relative">
          Nowości
          {/* <div
          className="
          w-[96px] h-[96px]"
        >
          <TrianglesDecoration />
        </div> */}
        </SectionTitle>
      </div>
      <div
        className="
        flex flex-col
        md:hidden
        xl:flex-row"
      >
        <HorizontalScroll
          className="
          mb-4 md:contents 
          md:mb-0"
        >
          {venues &&
            venues.map &&
            venues.map((venue: any) => {
              return (
                // <HorizontalScrollItem key={venue.id}>
                <HomeVenueTile
                  key={venue.id}
                  title={venue.name}
                  address={venue.address}
                  city={venue.city}
                  img={
                    venue.media && venue.media.length > 0
                      ? venue.media[0]
                      : undefined
                  }
                  href={`/miejsce/${venue.slug ?? venue.id}`}
                />
                // </HorizontalScrollItem>
              )
            })}
        </HorizontalScroll>
        <div
          className="
          col-start-3 row-start-1 row-end-4 self-stretch
        grid grid-rows-2 sm:grid-rows-none sm:grid-cols-2 gap-4 md:flex flex-col"
        >
          {/* <video className="w-full" controls autoPlay muted>
            <source
              src="https://assets.mojeurodziny.pl/www/mojeurodziny_anim01.mp4"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video> */}
          <FacebookPageEmbed />
        </div>
      </div>

      <div className="hidden md:block">
        <div className=" grid grid-cols-3 gap-4 ">
          {/* grid-rows-[200px_minmax(900px,_1fr)_100px] */}
          {venues &&
            venues.map &&
            venues
              .slice(0, Math.min(venues.length, 6))
              .map((venue: any, index) => {
                const colStart = index % 2
                // console.log("colstart", colStart)
                return (
                  <div
                    key={index}
                    className={`h-[350px] mb-4 col-span-1`}
                    style={{
                      gridColumnStart: colStart + 1,
                    }}
                  >
                    <HomeVenueTile
                      key={venue.id}
                      title={venue.name}
                      address={venue.address}
                      city={venue.city}
                      img={
                        venue.media && venue.media.length > 0
                          ? venue.media[0]
                          : undefined
                      }
                      href={`/miejsce/${venue.slug ?? venue.id}`}
                    />
                  </div>
                )
              })}

          <div
            className=""
            style={{
              gridColumnStart: 3,
              gridRow: "1 / 3",
            }}
          >
            <div className="h-full">
              <FacebookPageEmbed />
            </div>
          </div>

          <div
            className=""
            style={{
              gridColumnStart: 3,
              gridRow: " 3 / 3",
            }}
          >
            <div className="h-[310px] w-[410px]">
              <GoogleBannerV />
            </div>
          </div>
        </div>
      </div>
    </CenteredPageWrapper>
  )
}
