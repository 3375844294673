import React from "react"

export function HorizontalScrollItem(
  props: React.ComponentPropsWithoutRef<"div">
) {
  return (
    <div
      id="item"
      {...props}
      className="flex-shrink-0 w-full md:w-full [scroll-snap-align:start] w-[90rem]"
      // style={{
      //   scrollSnapAlign: "start",
      // }}
    />
  )
}

export default function HorizontalScroll(
  props: React.ComponentPropsWithoutRef<"div">
) {
  return (
    <div
      id="HorizontalScroll"
      {...props}
      className={`flex overflow-auto scroll-snap-type[x mandatory] ${
        props.className ?? ""
      }`}
    />
  )
}
