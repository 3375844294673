import { MojeHeader } from "../components/header/MojeHeader"
import { HomeSectionRecentlyAdded } from "../modules/home/HomeSectionRecentlyAdded"
import { HomeSectionHero } from "../modules/home/HomeSectionHero"
import { HomeSectionInfo } from "../modules/home/HomeSectionInfo"
import { HomeSectionInspiratons } from "../modules/home/HomeSectionInspirations"
import HomeHostCTA from "../modules/home/HomeHostCTA"
import HomeSectionBottomInspirations from "../modules/home/HomeSectionBottomInspirations"
import { MojeFooter } from "../components/MojeFooter"
import { MojeUrodzinyHead } from "../components/head/MojeUrodzinyHead"
import { HomeHero22 } from "../modules/home/HomeHero22"
import { HomeSection1 } from "../modules/home/HomeSection1"
import { HomeHostCTA22 } from "../modules/home/HomeHostCTA22"
import { HomeSectionLocations } from "../modules/home/HomeSectionLocations"

export default function Home(props) {
  const first2Articles = props.articles ?? []
  // ? props.articles.slice(0, Math.min(props.articles.length, 2))
  // : []
  const next2Articles =
    props.articles && props.articles.length > 2
      ? props.articles.slice(2, 2 + Math.min(props.articles.length - 2, 2))
      : []

  return (
    <div className="overflow-x-hidden">
      <MojeUrodzinyHead description="Najlepsze urodzinowe lokalizacje i usługi w swojej okolicy - Zorganizuj przyjęcie z MojeUrodziny.pl" />
      <HomeHero22 />
      <MojeHeader fixed articles={props.articles} />
      <HomeSection1 />
      <HomeSectionRecentlyAdded venues={props.recentlyAddedVenues} />
      <HomeSectionInspiratons articles={first2Articles} />
      <HomeHostCTA22 />
      <HomeSectionLocations stats={props.stats} />
      {/* <HomeSectionHero stats={props.stats} venues={props.venues} /> */}
      {/* <HomeSectionInfo />
      <HomeSectionBottomInspirations articles={next2Articles} />*/}
      <MojeFooter />
    </div>
  )
}

export async function getStaticProps() {
  const url = process.env.NEXT_PUBLIC_API_URL + "/public/homepage"
  var homePageData

  try {
    var res = await fetch(url).then((res) => res.json())
    homePageData = res
  } catch (err) {
    homePageData = {}
  }
  // console.log("homePageData", homePageData)
  // Load inspirations
  var articles
  try {
    const res = await fetch(
      process.env.NEXT_PUBLIC_API_URL + "/v1/public/articles"
    )
    articles = await res.json()
  } catch (err) {
    console.error("Can't load articles:", err)
  }

  return {
    props: {
      recentlyAddedVenues: homePageData.recentlyAddedVenues ?? [],
      stats: homePageData.stats ?? {},
      venues: homePageData.venues ?? [],
      articles: articles ?? [],
    },
    revalidate: 60, // In seconds
  }
}
