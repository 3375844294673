import { Photo } from "../../components/media/Photo"
import styled from "styled-components"
import { Link } from "../../components/Link"

const HomeVenueTileStyled = styled.div`
  //   display: inline;
  //   margin: 20px;
  //   width: 400px;
  //   height: 400px;
  padding: 10px;
  cursor: pointer;
  transition: scale 150ms ease-in-out;
  &:hover {
    .photo {
      transform: scale(1.02);
    }
  }
`
export const HomeVenueTile = (
  {
    title,
    address,
    href,
    img,
    city,
  }: {
    title: string
    address?: string
    href?: string
    img?: string
    city?: string
  } = {
    title: "Title",
  }
) => {
  const content = (
    <div
      className="
      w-[237px] md:w-[100%]
      h-[262px] md:h-[100%]
      mr-3
      flex flex-col
    "
    >
      <div
        className="
          h-[188px] md:h-auto md:min-h-0
          w-[237px] md:w-[100%]
          transform hover:--tw-scale-x-[1.02] hover:--tw-scale-y-[1.02]
          md:flex-1
            "
      >
        <Photo
          media={img ?? "/assets/venues/default.jpg"}
          // imgWidth={237 * 2}
          imgWidth={391}
          alt="Kids"
          radius
        />
      </div>
      <div className="my-2">
        <div className="md:h-8 overflow-hidden">
          {title && (
            <h3
              className="md:text-lg 
              font-medium 
              font-poppins 
              whitespace-nowrap 
              text-ellipsis pr-8"
            >
              {title}
            </h3>
          )}
        </div>
        {address && (
          <p
            className="text-sm
            font-light whitespace-nowrap text-ellipsis pr-8
            text-gray-600
            "
          >
            {address}
            {city ? `, ${city}` : ""}
          </p>
        )}
      </div>
    </div>
  )

  return href ? <Link href={href}>{content}</Link> : content
}
