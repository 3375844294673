import Head from "next/head"

export const MojeUrodzinyHead = ({
  title,
  url,
  description,
  image,
}: {
  title?: string
  url?: string
  description?: string
  image?: string
}) => {
  return (
    <Head>
      <title>{title ?? "Moje Urodziny"}</title>
      <meta
        property="og:title"
        content={title ?? "Moje Urodziny"}
        key="title"
      />
      <meta
        property="og:image"
        content={image ?? "https://www.mojeurodziny.pl/assets/logo_color.png"}
        key="image"
      />
      {description && (
        <meta
          property="og:description"
          content={description}
          key="description"
        />
      )}
      {/* <meta
        property="og:url"
        content={`https://www.mojeurodziny.pl/${url}`}
        key="url"
      /> */}
    </Head>
  )
}
